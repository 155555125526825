import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class WorkingProvider extends HttpRequest {
  findAll () {
    return this.get('/working-hours')
  }

  createGroup (payload) {
    return this.post('/working-hours', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/working-hours/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/working-hours/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/working-hours?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default WorkingProvider
