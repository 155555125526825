<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Holidays Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="OtherWorkingDetailForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <DatePicker
            v-model="otherWorking.date"
            :rules="{ required: true }"
            name="Date"
            label="Date" />
          <TextFieldInput
            v-model="otherWorking.note"
            :rules="{ required: true }"
            name="Note"
            label="Note"
            trim />
          <SelectField
            v-model="otherWorking.userGroupIds"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :options="userGroup"
            :rules="{ required: true }"
            name="GroupId"
            form-label="User Group"
            label-option="name"
            return-value="id" />
          <StatusRadioButton :status.sync="otherWorking.status" />
          <b-row>
            <b-col>
              Created At : {{ otherWorking.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ otherWorking.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ otherWorking.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ otherWorking.updatedBy.username }}
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue">
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              @click="deleteItem()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import dayjs from 'dayjs'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import DatePicker from '@/components/Form/DatePicker.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: { TextFieldInput, SelectField, DatePicker, StatusRadioButton },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialOtherWorking: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    },
    userGroup: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    otherWorking: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.otherWorking) === JSON.stringify(this.initialOtherWorking)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        id: this.otherWorking.id,
        date: this.otherWorking.date,
        note: this.otherWorking.note,
        userGroupIds: this.otherWorking.userGroupIds,
        status: this.otherWorking.status
      }
      this.$emit('update-working', payload)
    },
    resetForm () {
      this.otherWorking = { ...this.initialOtherWorking }
      this.$refs.OtherWorkingDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.otherWorking.id,
        UserGroup: this.otherWorking.userGroup.name,
        Date: dayjs(this.otherWorking.date).locale('en').format('DD MMM, YYYY'),
        Note: this.otherWorking.note
      })
    }
  }
}
</script>

<style>

</style>
