<template>
  <b-form-group
    :label="label"
    class="mb-0 font-weight-semibold">
    <flat-pickr
      v-model="innerValue"
      v-bind="$attrs"
      class="form-control bg-white"
      placeholder="YYYY/MM/DD"
    />
  </b-form-group>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr
  },
  props: {
    value: {
      type: [String, Date],
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>

</style>
